import React from 'react'
import { CarrouselCampus } from './components/CarrouselCampus'
import { useState, useEffect } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-scroll'
import { Link as LinkR } from 'react-router-dom'
import SplitType from 'split-type'
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { IoIosArrowDown } from "react-icons/io";
import 'animate.css';
import '../../static/styles/Landing.css'
import { isMobile } from 'react-device-detect';
import MapsTemplate from '../../shared/MapsTemplate'

export default function MasCoyoacan() {

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const imagesBuga = [
        "https://lh5.googleusercontent.com/p/AF1QipMWedmxDvDQQxRHVq_zoQ_3C_LelIhpIJVtJWd2=w203-h270-k-no",
        "https://lh5.googleusercontent.com/p/AF1QipNa1krbBxrLNo8Rm03wAq9oc6_zRIDznvP8qx_P=w203-h270-k-no"
    ];

    const navigation = [
        { name: 'Oferta Educativa', href: '/' },
        { name: 'Instalaciones', href: '/' },
        { name: 'Quienes somos', href: '/about' },
        { name: 'Misión y Visión', href: '/about' },
    ]

    const controlNavbar = () => {
        if (typeof window !== 'undefined') {
            if (window.scrollY > lastScrollY) {
                // if scrolling down, hide the navbar
                setShow(false);
            } else {
                // if scrolling up, show the navbar
                setShow(true);
            }

            // remember the current page location for the next move
            setLastScrollY(window.scrollY);
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', controlNavbar);

            // cleanup function
            return () => {
                window.removeEventListener('scroll', controlNavbar);
            };
        }
    }, [lastScrollY]);

    return (
        <div className="relaive overflow-hidden bg-gray-50 min-h-[100vh] flex items-start justify-center flex-col p-5">
            <header className={`z-50 backdrop-filter w-full h-[10vh]`}>
                <nav className="flex p-6 lg:px-8 relative" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <LinkR target='_black' to="/" className='bg-gradient-to-r from-gray-800 via-gray-900 to-gray-800 p-1 rounded-md'>
                            {/* <p className="text-indigo-600 font-extrabold">Larghettoaaa</p> */}
                            <img
                                className='w-20 h-auto p-1'
                                src={require("../../static/images/Logo Larghetto sin fondo.png")} alt="LogoAbout" />
                        </LinkR>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-purple-50"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6 absolute top-1/3 right-0" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <LinkR key={item.name} to={item.href} className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer">
                                {item.name}
                            </LinkR>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    </div>
                </nav>
                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-50" />
                    <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-purple px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 bg-opacity-80 bg-white">
                        <div className="flex items-center justify-between">
                            <LinkR to="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Your Company</span>
                                <p className='text-bg font-bold leading-6 text-black-700'>Larghetto</p>
                                {/* <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                /> */}
                            </LinkR>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>
            <div className='flex flex-col lg:flex-row items-start justify-center w-full h-auto sm:h-auto lg:h-[100vh] pb-5'>
                <div className='flex w-full lg:w-5/12 h-[100%] flex-col'>
                    <h2 className='mt-10 font-bold text-5xl text-[#272727]'>Coyoacán</h2>
                    <div className='mt-5 h-f[100%] text-clamp-xs'>

                        <div className='mb-5'>
                            Ubicado justo frente a la Terminal Central de Autobuses del Sur y la terminal de metro Taxqueña, cerca del campus encontrarás centros comerciales, salones de belleza, bancos, parques, supermercados, y mucho más.
                        </div>
                        <div className='mb-5'>
                            Al encontrarnos en avenida principal, es complicado estacionarse, sin embargo, en las calles aledañas existe mucho espacio para estacionarse, además a 200 metros del campus, en avenida Cerro de las Torres, se encuentra un supermercado de cadena internacional con estacionamiento, a su vez frente a dicho supermercado se encuentra un estacionamiento público.
                        </div>
                        <div className='mb-5'>
                            Si vienes en transporte público encontrarás el paradero de trolebús justo frente al campus, a su vez, cruzando la avenida, encontrarás la terminal de metro Taxqueña.
                        </div>
                        <div className='mb-5'>
                            Disfruta de salones perfectamente equipados con todo lo necesario, Smart tv de 60”, tratamiento acústico de alta densidad, área de espera, juegos de mesa, futbolito de salón, ventanales con tratamiento acústico, espejos de gran formato y pisos laminados.
                        </div>


                    </div>

                </div>
                <div className='flex items-center justify-center w-full lg:w-7/12 pt-3 pl-3 max-h-[100vh] h-[85%]'>
                    <div className='w-[90%] h-[90%]'>
                        <CarrouselCampus images={imagesBuga} />
                    </div>
                </div>

            </div>

            <div className='flex flex-col lg:flex-row items-start justify-start lg:justify-center sm:justify-start w-auto sm:w-auto lg:w-[100vw] md:w-[100vw] h-auto pb-5 mb-14'>
                <div className='flex flex-col items-center justify-center w-full h-full lg:w-2/3 pt-3'>
                    <div className='w-[100%] sm:w-[100%] md:w-[90%] lg:w-[100%] h-80 mt-0 sm:mt-0 lg:mt-0 md:mt-44'>
                        <MapsTemplate campus={"coyoacan"} />
                    </div>
                    <div className='mt-5'>
                        <p>Avenida Canal de Miramontes #1830, Col. Campestre Churubusco C.p. 04200, Coyoacán, Ciudad de México.                        </p>
                        <p>Tel. 5594627062</p>
                        <p>Whats. 5521048568</p>

                    </div>
                </div>

            </div>

            <div className='fixed bottom-0 left-0 flex flex-row lg:justify-start justify-center w-full bg-gray-50 sm:bg-gray-50 lg:bg-transparent'>
                <a
                    href="https://wa.me/+5521048568"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={require('../../static/images/whatsapp.png')} alt="" srcset="" className='w-[6vh] h-[6vh] m-1 p-0.5 cursor-pointer transition-transform duration-300 hover:scale-110' />
                </a>
                <a
                    href="https://www.facebook.com/LarghettoRMA/?locale=es_LA"
                    target="_blank"
                    rel="noopener noreferrer"
                    className='flex items-center'
                >
                    <img src={require('../../static/images/facebook.png')} alt="" srcset="" className='w-[5vh] h-[5vh] m-1 mr-2 cursor-pointer transition-transform duration-300 hover:scale-110' />
                </a>
                <a
                    href="https://www.instagram.com/larghetto_academia/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className='flex items-center'
                >
                    <img src={require('../../static/images/instagram.png')} alt="" srcset="" className='w-[5vh] h-[5vh] m-1 cursor-pointer transition-transform duration-300 hover:scale-110' />
                </a>
            </div>

        </div>
    )
}

import { useState, useEffect } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-scroll'
import { Link as LinkR } from 'react-router-dom'
import SplitType from 'split-type'
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { IoIosArrowDown } from "react-icons/io";
import 'animate.css';
import '../../../static/styles/Landing.css'
import { isMobile } from 'react-device-detect';
import { FaWhatsapp, FaInstagram, FaFacebook } from 'react-icons/fa';

const navigation = [
  { name: 'Oferta Educativa', href: 'oferta' },
  { name: 'Instalaciones', href: 'campusBuga' },
]
const navigationFix = [
  { name: '¿Quiénes somos?', href: '/about' , target: '#' },
  { name: 'Misión y Visión', href: '/about', target: '#' },
  { name: 'Contactanos', href: 'https://wa.me/+7775341941?text=Hola,%20me%20gustaría%20obtener%20más%20información%20sobre%20sus%20clases.', target: '_blank' },
]

const socialIcons = [
    { name: 'Whatsapp', href: 'https://wa.me/+7775341941?text=Hola,%20me%20gustaría%20obtener%20más%20información%20sobre%20sus%20clases.', icon: <FaWhatsapp /> },
  { name: 'Instagram', href: 'https://www.instagram.com/larghetto_academia/', icon: <FaInstagram /> },
  { name: 'Facebook', href: 'https://www.facebook.com/LarghettoRMA', icon: <FaFacebook /> },
];


export default function Hero() {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  // useEffect(() => {
  //   SplitType.create('#titulo');

  //   let characters = document.querySelectorAll('.char');
  //   for (let i = 0; i < characters.length; i++) {
  //     characters[i].classList.add('translate-y-full');
  //   }

  //   gsap.to('.char', {
  //     y: 0,
  //     stagger: 0.05,
  //     delay: 0.02,
  //     duration: 0.5
  //   });
  //   gsap.to('#subtitulo', {
  //     x: 0,
  //     stagger: 0.05,
  //     duration: 1
  //   });



  // }, []);

  // // useEffect(() => {
  // //   alert(`${window.innerWidth} , ${window.innerHeight}`)
  // // }, [])


  // const controlNavbar = () => {
  //   if (typeof window !== 'undefined') {
  //     if (window.scrollY > lastScrollY) {
  //       // if scrolling down, hide the navbar
  //       setShow(false);
  //     } else {
  //       // if scrolling up, show the navbar
  //       setShow(true);
  //     }

  //     // remember the current page location for the next move
  //     setLastScrollY(window.scrollY);
  //   }
  // };

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     window.addEventListener('scroll', controlNavbar);

  //     // cleanup function
  //     return () => {
  //       window.removeEventListener('scroll', controlNavbar);
  //     };
  //   }
  // }, [lastScrollY]);

  return (
    <div className="bg-black h-[100vh]">
      <header className={`fixed inset-x-0 top-10 z-50 backdrop-filter backdrop-blur-lg bg-opacity-10 transition-transform duration-300 transform ${show ? 'translate-y-0' : '-translate-y-full'}`}>
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <Link spy={true} smooth={true} offset={-300} duration={500} to="home" className="-m-1.5 p-1.5 cursor-pointer" >
              <span className="sr-only">Larghetto</span>
              {/* <p className='text-bg font-bold leading-6 text-purple-50'>Larghetto</p> */}
              <img
                className="h-auto w-20"
                src={require("../../../static/images/Logo Larghetto sin fondo.png")}
                alt=""
              />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-purple-50"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (

              <Link key={item.name} spy={true} smooth={true} offset={70} duration={500} to={item.href} className="text-sm font-semibold leading-6 text-purple-50 cursor-pointer">
                {item.name}
              </Link>
            ))}
            {navigationFix.map((item) => (
              <LinkR key={item.name} to={item.href} className="text-sm font-semibold leading-6 text-purple-50 cursor-pointer" target={item.target}>
                {item.name}
              </LinkR>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 gap-2 lg:justify-end text-white">
            {socialIcons.map((item) => (
              <LinkR key={item.name} to={item.href} className="text-xl font-semibold leading-6 text-purple-50 cursor-pointer" target='_blank'>
                {item.icon}
              </LinkR>
            ))}
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-purple px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10  bg-opacity-80 bg-white">
            <div className="flex items-center justify-between">
              <LinkR to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <p className='text-bg font-bold leading-6 text-black-700'>Larghetto</p>
                {/* <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                /> */}
              </LinkR>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6 flex flex-col">
                  {navigation.map((item) => (
                    <Link key={item.name} spy={true} smooth={true} offset={70} duration={500} to={item.href} className="text-sm font-semibold leading-6 text-black cursor-pointer">
                      {item.name}
                    </Link>
                  ))}
                  {navigationFix.map((item) => (
                    <LinkR key={item.name} to={item.href} className="text-sm font-semibold leading-6 text-black cursor-pointer">
                      {item.name}
                    </LinkR>
                  ))}
                </div>

              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      {isMobile && <div className='relative w-full bg-black flex justify-center border border-red-600 border-t-2 border-l-0 border-r-0'>
        <video width="100%" autoPlay muted loops playsInline className='imageGlow top-0 absolute'>
          <source src={require('../../../static/images/VideoLandingSM.mp4')} type="video/mp4" />
          Tu navegador no soporta la etiqueta de video.
        </video>


        <div class="absolute bottom-40 lg:bottom-20 animate__animated animate__bounce animate__infinite animate__slow flex flex-col items-center justify-center z-10 text-white">
          <div>
            Baja para conocer más
          </div>
          <IoIosArrowDown />
        </div>
      </div>}

      {!isMobile && <div className='relative w-full h-[100%] bg-black flex justify-center'>
        <video width="100%" autoPlay muted loops className='imageGlow'>
          <source src={require('../../../static/images/VideoLanding.mp4')} type="video/mp4" />
          Tu navegador no soporta la etiqueta de video.
        </video>

        <div class="absolute bottom-40 lg:bottom-20 animate__animated animate__bounce animate__infinite animate__slow flex flex-col items-center justify-center z-10 text-white">
          <div>
            Baja para conocer más
          </div>
          <IoIosArrowDown />
        </div>
      </div>}

    </div>
  )
}

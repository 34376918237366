import './App.css';
import AppRouter from './shared/AppRouter';
import React, { useEffect } from 'react';
import 'animate.css';


function App() {

  //Agregar titulo, descripción y keywords
  useEffect(() => {
    document.title = "Larghetto | Academia de Música";
    document.querySelector('meta[name="description"]').setAttribute("content", "Descubre tu potencial musical en nuestra academia en Morelos con campus en Bugambilias, Centro y Cuautla. Clases para niños, adolescentes y adultos. Aprende piano, guitarra, violín y más. ¡Únete y desata tu creatividad hoy!");

    const keywords = "academia de música, Morelos, Bugambilias, Centro, Cuautla, clases de música, niños, adolescentes, adultos, piano, guitarra, violín";
    const metaKeywords = document.createElement('meta');
    metaKeywords.name = 'keywords';
    metaKeywords.content = keywords;
    document.head.appendChild(metaKeywords);
    document.documentElement.lang = "es";
  }, []);

  return (
    <div className="App relative">
      <AppRouter />
      <div
        className="bg-red-600 text-white grid items-center fixed top-0 w-full h-10 z-10 animate__animated animate__pulse animate__infinite animate__slow cursor-pointer font-semibold"
        onClick={() => window.open('https://wa.me/+7775341941?text=Quiero%20una%20clase%20gratis', '_blank')}
      >
        ¡Quiero una clase gratis!
      </div>
    </div>
  );

}

export default App;

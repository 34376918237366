import React, { useState } from 'react';
import Sparkles from '../../animatedLanding/components/Sparkle';

const files = [
  {
    source: 'https://images.pexels.com/photos/1625355/pexels-photo-1625355.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Curso de Instrumento o Canto",
    description: "Nuestras clases son grupales de máximo 6 alumnos por salón (con edades similares) y una duración de 2 horas a la semana impartidas en 1 solo día. En los talleres, todos los alumnos tendrán a su disposición instrumentos de gran calidad, los cuales reciben mantenimiento periódicamente para de esta forma garantizar el perfecto funcionamiento de los mismos. El plan de estudios se encuentra dividido en 9 niveles (Básico 1, 2 y 3; Intermedio 1, 2 y 3; y Avanzado 1, 2 y 3), cada nivel con una duración de 4 meses. La duración total del taller de 3 años (NO OBLIGATORIOS). En cada nivel, se llevan bitácoras de estudios con lo que profesores, directores, alumnos y padres de familia, estamos en sintonía, sabiendo cuales son los temas que se han visto, cual es el tema y nivel exacto en el que nos encontramos con el paso del tiempo y los objetivos que tenemos por delante; lo cual garantiza que las clases tengan una estructura sólida y se optimice el tiempo de estudio de cada uno de nuestros alumnos para así lograr los resultados buscados en cada nivel de nuestro plan de estudios. (Poner 5 fotos reales de diferentes clases)"
  },
  {
    source: 'https://images.pexels.com/photos/210804/pexels-photo-210804.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Taller de Teoría Musical",
    description: "Este curso es gratuito para todos los alumnos de 12 años en adelante inscritos en Larghetto. Esta diseñado para cubrir materias importantes tales como: Solfeo, Entrenamiento Auditivo, Armonía, Apreciación Musical. Los talleres inician cada 4 meses, en los meses de Enero, Mayo y Septiembre, las clases son grupales de máximo 15 alumnos y tienen una duración de una hora a la semana. (Poner foto real de una clase de teoría)"
  },
  {
    source: 'https://images.pexels.com/photos/210887/pexels-photo-210887.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Taller de Ensambles",
    description: "Ensambles es la parte más divertida y que en Larghetto amamos con locura, ya que aquí se refleja el trabajo del día a día de nuestras clases. Los talleres de ensambles son totalmente gratuitos para todos los alumnos regulares de Larghetto que tengan al menos 12 meses en clases y hayan aprobado satisfactoriamente los niveles B1, B2 y B3 de su respectivo instrumento, así como del taller de Teoría Musical. Las clases son impartidas una vez a la semana en donde participarán activamente para el montaje de un repertorio musical, de genero aleatorio, que posteriormente se presentará en recitales abiertos a todo público en los meses de Mayo y Agosto, para finalizar con un concierto en el mes de Diciembre. (Poner foto real de una clase de ensamble)"
  },
  {
    source: 'https://images.pexels.com/photos/716276/pexels-photo-716276.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Clínicas",
    description: "En Larghetto, somos conscientes de la importancia de las relaciones públicas y de la necesidad que, como artistas, tenemos de abrir nuestros horizontes a más artistas para así entender y visualizar el arte y la música desde diferentes perspectivas. Por ello, realizamos activamente en todos nuestros campus Master Class con artistas, ingenieros de audio, productores musicales y empresarios de la música que se encuentren activos en el medio."
  },
  {
    source: 'https://images.pexels.com/photos/7942545/pexels-photo-7942545.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Diplomados en Música",
    description: "En Larghetto contamos con certificaciones internacionales avaladas por RSL y ABRSM con validez oficial en más de 90 países. Si realmente quieres dedicarte a la música de manera profesional, los diplomados en música son tu mejor opción. Las clases se imparten de manera escolarizada en 2 formatos distintos a elección de cada alumno: Full Time, con un total de 14 horas a la semana de clases presenciales con la siguiente distribución: 6 horas de instrumento principal, 2 horas de instrumento complementario, 2 horas de clínica, 1 hora de solfeo, 1 hora de armonía, 1 hora de entrenamiento auditivo, 1 hora de ensamble. Half Time, con un total de 14 horas a la semana de clases presenciales con la siguiente distribución: 6 horas de instrumento principal, 2 horas de instrumento complementario, 1 hora de teoría musical. (Poner logos de RSL y ABRSM)"
  },
  {
    source: 'https://images.pexels.com/photos/8471823/pexels-photo-8471823.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Larghetto Kids",
    description: "Es un curso especializado en música para los más pequeñines de entre 4 a 7 años de edad. Larghetto Kids es una introducción a la música, lenguaje, rítmica y arte en general, en donde a través de juegos y una gran variedad de dinámicas conseguimos que los alumnos desarrollen su función psicomotriz al tiempo que se enamoran del arte y la música en general. Larghetto Kids tiene inmerso en el curso las siguientes materias: Solfeo, Armonía, Entrenamiento Auditivo, Apreciación Musical, Historia de la música, Canto, Melódica, Flauta Dulce, Percusiones, Ukelele. Las clases son impartidas una vez a la semana con una duración de hora y media a dos horas, en salones adaptados para salvaguardar la integridad de nuestros alumnos al tiempo de desarrollar su imaginación e interés por las dinámicas propias del curso. (Poner foto real de un salón acondicionado para Larghetto Kids)"
  },
  {
    source: 'https://images.pexels.com/photos/7715754/pexels-photo-7715754.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Recitales y Conciertos",
    description: "Nos enorgullecemos de saber que el 100% de nuestros alumnos pueden tocar su instrumento o bien cantar, para ello desde los primeros meses de haber ingresado a Larghetto, todos nuestros alumnos son preparados para tener presentaciones en vivo frente a público, esto es con la finalidad de ir fomentando ese control de escenario y poner objetivos claros a corto plazo para de esta forma medir el progreso sobre campo en un ambiente de práctica real. (poner un video aquí o bien un Widget del perfil de Larghetto en Youtube)"
  },
  {
    source: 'https://images.pexels.com/photos/7090869/pexels-photo-7090869.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Salones de estudio",
    description: "Larghetto es tu escuela, por ello, sin costo alguno podrás venir tantas veces como lo desees a practicar en tu instrumento, siempre siguiendo el reglamento de alumnos, horarios y disponibilidad en cada área. Los salones siempre estarán habilitados con todo lo necesario a su vez, podrás solicitar todo el material que necesites para tener una sesión de estudio placentera y productiva. (Poder 5 Fotos de diferentes salones)"
  },
  {
    source: 'https://images.pexels.com/photos/7974876/pexels-photo-7974876.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Baile y danza contemporánea",
    description: "Muy pronto."
  }
];

export default function OfertaEducativaCards() {
  const [selected, setSelected] = useState(null);

  const handleFlip = (index) => {
    setSelected(selected === index ? null : index);
  };

  return (
    <ul role="list" className={`grid grid-cols-1 gap-2`}>
      {files.map((file, index) => (
        <li
          key={file.source}
          className={`relative group col-span-1 perspective cursor-pointer transition-all duration-[1500ms] ${selected === index ? 'w-full h-[32rem]' : 'w-full h-32'}`}
          onClick={() => handleFlip(index)}
        >
          <div className={`relative w-full h-full transition-transform duration-[2000ms] transform-style-preserve-3d ${selected === index ? 'rotate-y-180 my-3' : ''}`}>
            <div className="absolute backface-hidden w-full h-full">
              <img src={file.source} alt="" className="object-cover w-full h-full filter brightness-50" />
              <div className="absolute inset-0 flex items-center justify-center text-white text-3xl font-bold bg-opacity-0">
                <Sparkles>
                  {file.name}
                </Sparkles>
              </div>
            </div>
            <div className="absolute backface-hidden w-full h-full bg-black text-white p-4 transform rotate-y-180 text-justify grid items-center">
              {file.description}
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}
import { MusicalNoteIcon, GlobeAmericasIcon, PaintBrushIcon } from '@heroicons/react/20/solid'
import '../../../static/styles/GlowCircle.css'
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useEffect } from 'react';
import Sparkles from '../../animatedLanding/components/Sparkle';
import { Link } from 'react-router-dom';
import MapsTemplate from '../../../shared/MapsTemplate';
gsap.registerPlugin(ScrollTrigger)


export default function FeatureSectionCuautlaCopy() {

    return (
        <div className="overflow-hidden bg-gradient-to-b3 h-auto sm:h-auto lg:h-[100vh] md:h-[100vh] w-[100vw] py-10" id='campusBuga'>

            <div className=' w-auto h-full mx-6 lg:mx-6 md:flex sm:hidden lg:flex hidden flex-row'>

                <div className='w-1/6 lg:w-1/6 md:w-0 sm:w-0'>
                </div>
                <div className=' h-full w-2/6 lg:w-2/6 md:w-3/6 sm:w-3/6 flex flex-col'>
                    <div className=' h-5/6 p-3 relative'>
                        <img
                            src="https://lh3.googleusercontent.com/p/AF1QipN__BrjOHcFYmzPsRvB5xbxY94MJrmZrxyuvn3_=s680-w680-h510"
                            alt="Product screenshot"
                            className="w-full h-full imageGlow2"
                        />
                        <Link to={"/cuautla"} target='_blank'  className="absolute top-3 left-3 w-0 h-0 border-r-[100px] border-r-transparent border-t-[100px] border-t-black hover:scale-110 transform transition duration-300 ease-in-out">
                        </Link>
                        <Link to={"/cuautla"} target='_blank'  className='absolute top-5 left-7 text-gray-300 italic'>Saber</Link>
                        <Link to={"/cuautla"} target='_blank'  className='absolute top-9 left-7 text-gray-300 italic'>Más</Link>
                    </div>
                    <div className=' h-1/6 p-3 grid items-center text-purple-50 text-md italic'>
                        "Cuautla, tierra de historia y revolución, donde el valor de su gente resuena entre paisajes llenos de tradición y belleza."
                    </div>
                </div>

                <div className=' h-full w-2/6 lg:w-2/6 md:w-3/6 sm:w-3/6 flex flex-row'>

                    <div className=' w-full flex flex-col-reverse'>
                        <div className=' h-3/6 py-3'>
                            <MapsTemplate campus={"cuautla"} />
                        </div>
                        <div className=' h-2/6 imageGlow2'>
                            <img src="https://images.pexels.com/photos/145707/pexels-photo-145707.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt=""
                                className='h-full p-0 w-full' />
                        </div>
                        <div className=' h-1/6 grid items-center'>
                            <div>

                                <h2 className="text-base font-semibold leading-7 text-indigo-600">Morelos</h2>
                                <Sparkles children={<p className="mt-2 text-3xl font-bold tracking-tight text-purple-50 sm:text-4xl">Cuautla</p>
                                } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Movil */}
            <div className=' w-auto h-full mx-6 lg:mx-6 md:hidden sm:flex lg:hidden flex flex-col'>
                <div>

                    <h2 className="text-base font-semibold leading-7 text-indigo-600">Morelos</h2>
                    <Sparkles children={<p className="mt-0 text-3xl font-bold tracking-tight text-purple-50 sm:text-4xl">Cuautla</p>
                    } />
                </div>
                <Link to={"/cuautla"} target='_blank'   className='mx-3 mt-3 p-2 bg-gray-900 text-white'>Saber más</Link>
                <div className='p-3'>
                    <img
                        src="https://lh3.googleusercontent.com/p/AF1QipN__BrjOHcFYmzPsRvB5xbxY94MJrmZrxyuvn3_=s680-w680-h510"
                        alt="Product screenshot"
                        className="w-full h-full imageGlow2"

                    />
                </div>
                <div className='my-5 grid items-center text-purple-50 text-md italic'>
                "Cuautla, tierra de historia y revolución, donde el valor de su gente resuena entre paisajes llenos de tradición y belleza."
                </div>
                <div className='w-full h-80'>
                    <MapsTemplate campus={"cuautla"} />
                </div>
                
            </div>
        
        </div>
    )
}

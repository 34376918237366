import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from '../templates/landingpage/LandingPage';
import AnimatedLanding from '../templates/animatedLanding/AnimatedLanding';
import AboutUs from '../templates/about/AboutUs';
import MasBuga from '../templates/MasCampus/MasBuga';
import MasCentro from '../templates/MasCampus/MasCentro';
import MasCuautla from '../templates/MasCampus/MasCuautla';
import MapsTemplate from './MapsTemplate';
import MasCoyoacan from '../templates/MasCampus/MasCoyoacan';

const AppRouter = () => {
    return (
        <div className='mt-10'>
            <Router>
                <Routes>
                    <Route path="/lol" index element={<AnimatedLanding />} />
                    <Route path="/" element={<LandingPage />} />

                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/contact" element={<>Contact</>} />
                    <Route path="/bugambilias" element={<MasBuga />} />
                    <Route path="/coyoacan" element={<MasCoyoacan />} />
                    <Route path="/centro" element={<MasCentro />} />
                    <Route path="/cuautla" element={<MasCuautla />} />
                    <Route path="/maps" element={<MapsTemplate />} />

                </Routes>
            </Router>
        </div>
    );
};

export default AppRouter;

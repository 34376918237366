import { MusicalNoteIcon, GlobeAmericasIcon, PaintBrushIcon } from '@heroicons/react/20/solid'
import '../../../static/styles/GlowCircle.css'
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useEffect } from 'react';
import Sparkles from '../../animatedLanding/components/Sparkle';
import { Link } from 'react-router-dom';
import MapsTemplate from '../../../shared/MapsTemplate';
gsap.registerPlugin(ScrollTrigger)


const features = [
    {
        name: 'Oasis de Tranquilidad',
        description:
            'Un refugio dentro del ajetreo del trabajo, donde el silencio y la paz reinan. Un espacio con una atmósfera serena y libre de distracciones, ideal para concentrarse y realizar tareas con máxima eficiencia.',
        icon: MusicalNoteIcon,
    },
    {
        name: 'Arte que Inspira',
        description: 'Un espacio de trabajo que no solo sea funcional, sino también un festín para los ojos. Murales vibrantes y llenos de color',
        icon: PaintBrushIcon,
    },
    {
        name: 'Un oasis verde dentro de la oficina, donde puedes conectar con la naturaleza y disfrutar de sus beneficios',
        description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
        icon: GlobeAmericasIcon,
    },
]


export default function FeatureSectionBUgambiliasCopy() {

    useEffect(() => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: '#textBuga',
                start: '-40% center',
                end: '100% center',
                scrub: true,
                markers: false,
                toggleActions: 'play reverse play reverse'
            }
        });

        tl.to('#textBuga', {
            x: 0,
            duration: 3
        });

        let tlf = gsap.timeline({
            scrollTrigger: {
                trigger: '.featureB',
                start: '-60% 70%',
                end: '90% center',
                scrub: true,
                markers: false,
                toggleActions: 'play reverse play reverse'
            }
        });

        tlf.to('.featureB', {
            x: 0,
            duration: 3
        });

        let tli = gsap.timeline({
            scrollTrigger: {
                trigger: '#imgBuga',
                start: '30% 50%',
                end: '200% center',
                scrub: true,
                markers: false,
                toggleActions: 'play reverse play reverse'
            }
        });

        tli.to('#imgBuga', {
            x: 0,
            duration: 3
        });



    }, []);
    return (
        <div className="overflow-hidden bg-gradient-to-b3 h-auto sm:h-auto lg:h-[100vh] md:h-[100vh] w-[100vw] py-10" id='campusBuga'>
            {/* Pc - Tablet */}
            <div className=' w-auto h-full mx-6 lg:mx-6 md:flex sm:hidden lg:flex hidden flex-row'>

                <div className='w-0 lg:w-1/6 md:w-0 sm:w-0'>
                </div>
                <div className=' h-full w-2/6 lg:w-2/6 md:w-3/6 sm:w-3/6 flex flex-col'>
                    <div className=' h-5/6 p-3 relative'>
                        <img
                            src="https://lh3.googleusercontent.com/p/AF1QipNa1krbBxrLNo8Rm03wAq9oc6_zRIDznvP8qx_P=s680-w680-h510"
                            alt="Product screenshot"
                            className="w-full h-full imageGlow2"
                        />
                        <Link to={"/bugambilias"} target='_blank' className="absolute top-3 left-3 w-0 h-0 border-r-[100px] border-r-transparent border-t-[100px] border-t-black hover:scale-110 transform transition duration-300 ease-in-out">
                        </Link>
                        <Link to={"/bugambilias"} target='_blank' className='absolute top-5 left-7 text-gray-300 italic'>Saber</Link>
                        <Link to={"/bugambilias"} target='_blank' className='absolute top-9 left-7 text-gray-300 italic'>Más</Link>

                    </div>
                    <div className=' h-1/6 p-3 grid items-center text-purple-50 text-md italic'>
                        "Las bugambilias, con su vibrante manto de colores, son el abrazo de la naturaleza que transforma cualquier rincón en un jardín de alegría y esperanza."
                    </div>
                </div>

                <div className=' h-full w-2/6 lg:w-2/6 md:w-3/6 sm:w-3/6 flex flex-row'>

                    <div className=' w-full flex flex-col-reverse'>
                        <div className=' h-3/6 py-3'>
                            <MapsTemplate campus={"bugambilias"} />
                        </div>
                        <div className=' h-2/6 imageGlow2'>
                            <img src="https://images.pexels.com/photos/1128440/pexels-photo-1128440.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt=""
                                className='h-full p-0' />
                        </div>
                        <div className=' h-1/6 grid items-center'>
                            <div className=''>

                                <h2 className="text-base font-semibold leading-7 text-indigo-600">Jiutepec</h2>
                                <Sparkles children={<p className="mt-0 text-3xl font-bold tracking-tight text-purple-50 sm:text-4xl">Bugambilias</p>
                                } />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* Movil */}
            <div className=' w-auto h-full mx-6 lg:mx-6 md:hidden sm:flex lg:hidden flex flex-col'>
                <div>

                    <h2 className="text-base font-semibold leading-7 text-indigo-600">Jiutepec</h2>
                    <Sparkles children={<p className="mt-0 text-3xl font-bold tracking-tight text-purple-50 sm:text-4xl">Bugambilias</p>
                    } />
                </div>
                <Link to={"/bugambilias"} target='_blank' className='mx-3 mt-3 p-2 bg-gray-900 text-white'></Link>
                <div className='p-3'>
                    <img
                        src="https://lh3.googleusercontent.com/p/AF1QipNa1krbBxrLNo8Rm03wAq9oc6_zRIDznvP8qx_P=s680-w680-h510"
                        alt="Product screenshot"
                        className="w-full h-full imageGlow2"

                    />
                </div>
                <div className='my-5 grid items-center text-purple-50 text-md italic'>
                    "Las bugambilias, con su vibrante manto de colores, son el abrazo de la naturaleza que transforma cualquier rincón en un jardín de alegría y esperanza."
                </div>
                <div className='w-full h-80'>
                    <MapsTemplate campus={"bugambilias"} />
                </div>

            </div>


        </div>
    )
}

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

import '../../../static/styles/Landing.css'

const files = [
  {
    source:
      'https://images.pexels.com/photos/5648355/pexels-photo-5648355.jpeg?auto=compress&cs=tinysrgb&w=600',
    name: "Canto"
  },
  {
    source:
      'https://images.pexels.com/photos/4127953/pexels-photo-4127953.jpeg?auto=compress&cs=tinysrgb&w=600',
    name: "Piano"
  },
  {
    source:
      'https://images.pexels.com/photos/9418571/pexels-photo-9418571.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load',
    name: "Guitarra"
  },
  {
    source:
      'https://images.pexels.com/photos/7902142/pexels-photo-7902142.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load',
    name: "Batería"
  },
  {
    source:
      'https://images.pexels.com/photos/18603595/pexels-photo-18603595/free-photo-of-hombre-musica-musico-concierto.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Bajo"
  },
  {
    source:
      'https://images.pexels.com/photos/7095031/pexels-photo-7095031.jpeg?auto=compress&cs=tinysrgb&w=600',
    name: "Violín"
  },
  {
    source:
      'https://images.pexels.com/photos/13768516/pexels-photo-13768516.jpeg?auto=compress&cs=tinysrgb&w=600',
    name: "Violonchelo"
  },
  {
    source:
      'https://images.pexels.com/photos/9002855/pexels-photo-9002855.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Saxofón"
  },
  {
    source:
      'https://images.pexels.com/photos/4586658/pexels-photo-4586658.jpeg',
    name: "Ukelele"
  },
  {
    source:
      'https://images.pexels.com/photos/8471900/pexels-photo-8471900.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Larghetto Kids"
  },
  {
    source:
      'https://images.pexels.com/photos/8520094/pexels-photo-8520094.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Acordeón"
  },
  {
    source:
      'https://images.pexels.com/photos/16704486/pexels-photo-16704486/free-photo-of-jugando-musica-musico-cara.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Trompeta"
  },
]

export default function CampusCards() {
  return (
    <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
      {files.map((file) => (
        <li key={file.source} className="relative">
          <div className="group block w-full bg-transparent focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden imageGlow2 cursor-pointer transition-transform hover:scale-110">
            <img src={file.source} alt="" className="object-cover pointer-events-none group-hover:opacity-95" />
            <div className='bg-transparent text-white'>{file.name}</div>
          </div>
        </li>
      ))}
    </ul>
  )
}
